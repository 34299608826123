@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Alata', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

img {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

td, th {
  padding: 2px 6px;
  border: 1px solid #000 !important;
}

.ant-layout.layout {
  min-height: 100vh;
  background: #FFF;
}

.ant-statistic {
  text-align: center;
}

.ant-modal-body input {
  width: 95%;
}

@media(max-width:620px) {
  .hide-mobile {
    display: none !important;
  }
  .site-layout-content {
    margin: 0px !important;
  }
}

@media(max-width:900px) {
  .hide-mobile-900 {
    display: none !important;
  }
}

@media(max-width:1200px) {
  .hide-mobile-1200 {
    display: none !important;
  }
}

@media(min-width:621px) {
  .hide-desktop {
    display: none !important;
  }
}

@media(min-width:901px) {
  .hide-desktop-900 {
    display: none !important;
  }
}

@media(min-width:1201px) {
  .hide-desktop-1200 {
    display: none !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #BBBBBB;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

